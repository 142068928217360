/**
 * This function adds event listeners to handle external links.
 * It automatically sets external links to open in a new tab,
 * while leaving internal links, mailto links, tel links, and javascript links unaffected.
 */

export default function init() {
    document.addEventListener('click', function(e) {
        if (e.target.tagName === 'A') {
            const href = e.target.getAttribute('href');
            if (href && !href.startsWith('mailto:') && !href.startsWith('tel:') && !href.startsWith('javascript:')) {
                const currentHost = window.location.hostname;
                const linkHost = new URL(href, window.location.origin).hostname;
                
                if (linkHost !== currentHost) {
                    e.target.setAttribute('target', '_blank');
                    e.target.setAttribute('rel', 'noopener noreferrer');
                }
            }
        }
    });
}