export default function init() {
  // Target element
  const target = document.querySelector('.b-site-header-wrapper');

  let lastScrollY = window.scrollY;  // Store the last scroll position

  // Scroll event listener
  window.addEventListener('scroll', () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY) {
        // Scrolling down
        // Check the direction of scroll or if current scroll is less than height of the header
        if (currentScrollY < target.clientHeight) {
            target.classList.remove('is-active');
            target.classList.remove('is-hidden');
            target.classList.remove('is-scrolling');
            return;
        }
        // all other scrolling
        if (!target.classList.contains('is-active')) {
          target.classList.remove('is-hidden');
          target.classList.add('is-active');
        }
      } else {
          // Scrolling up
          // Check the direction of scroll or if current scroll is less than height of the header
        if (currentScrollY < target.clientHeight) {
          target.classList.add('is-active');
          target.classList.remove('is-hidden');
          target.classList.remove('is-scrolling');
          return;
        }
        if (target.classList.contains('is-active')) {
          target.classList.remove('is-active');
          target.classList.add('is-hidden');
        }
      }

      lastScrollY = currentScrollY;  // Update the last scroll position
  });
}
