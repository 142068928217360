function socialIcons() {
  if (typeof Cookiebot !== 'undefined' && Cookiebot?.consent?.marketing) {
    const list = document.querySelectorAll('.e-social-list__accept-cookies');
    for (let i = 0; i < list.length; i += 1) {
      list[i].style.display = 'flex';
    }
  } else {
    const list = document.querySelectorAll('.e-social-list__no-cookies');
    for (let i = 0; i < list.length; i += 1) {
      list[i].style.display = 'flex';
    }
  }
}

export default function init() {
  socialIcons();
}
