import '@styles/elements/e-blockquote.scss';
import '@styles/elements/e-cms-figure.scss';
import '@styles/blocks/b-breakout-copy.scss';
import '@styles/blocks/b-text-image-block.scss';
import '@styles/blocks/b-code-snippet.scss';
import '@styles/blocks/b-social-embed.scss';
import '@styles/blocks/b-video.scss';
import '@styles/blocks/b-client-testimonial-carousel.scss';

import codepenEmbed from '@modules/codepenEmbed';
import prism from '@modules/prism';

export default function init() {
  codepenEmbed();
  prism();
}
